import React, { useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Icon,
  Block,
  UserAvatar,
} from "../../components/Component";
import parse from "html-react-parser";
import { Card } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPostDetail } from "../../slice/post-detail";
import Slider from "react-slick";
import { findUpper } from "../../utils/Utils";
import moment from "moment/moment";

const settings = {
  className: "slider-init",
  slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 1,
  infinite: false,
  responsive: [
    { breakpoint: 1539, settings: { slidesToShow: 1 } },
    { breakpoint: 992, settings: { slidesToShow: 1 } },
    { breakpoint: 768, settings: { slidesToShow: 1 } },
  ],
  arrows: false,
  dots: true,
};
const PostDetailsPage = ({ match }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPostDetail(id));
  }, [id, dispatch]);
  const { post } = useSelector((state) => state.postDetail);

  return (
    <React.Fragment>
      <Head title="Post Detail"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <BlockTitle>Post Details</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Link to={`/posts`}>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              </Link>
              <Link to={`/posts`}>
                <Button
                  color="light"
                  outline
                  className="btn-icon bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
              </Link>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {post && (
          <Block>
            <Card>
              <div className="card-inner">
                <Card className="gallery">
                  <Row className="pb-5">
                    {post.assets?.length > 0 && (
                      <Col lg={6}>
                        <Slider {...settings}>
                          {post.assets.map((asset, index) => (
                            <div className={`slider-item`} key={index}>
                              {asset.type === "video" ? (
                                <div
                                  className="thumb w-100"
                                  style={{ aspectRatio: 1 }}
                                >
                                  <video
                                    src={asset.link}
                                    alt=""
                                    width={9999}
                                    style={{
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="thumb w-100"
                                  style={{ aspectRatio: 1 }}
                                >
                                  <img
                                    src={asset.link}
                                    width={9999}
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </Slider>
                      </Col>
                    )}
                    <Col lg={post.assets?.length > 0 ? 6 : 12}>
                      <div className="gallery-body card-inner align-start justify-between flex-column g-2">
                        <div className="d-flex justify-content-between g-2 w-100">
                          <div className="user-card">
                            <UserAvatar
                              text={findUpper(post.createdBy.fullName)}
                              image={post.createdBy.avatar}
                            ></UserAvatar>
                            <div className="user-info">
                              <span className="lead-text">
                                {post.createdBy.fullName}
                              </span>
                              <span className="sub-text">
                                {moment(post.createdAt).fromNow()}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex g-2">
                            <Button className="btn-p-0 btn-nofocus">
                              <Icon name="heart"></Icon>
                              <span>{post.liked.length}</span>
                            </Button>
                            <Button className="btn-p-0 btn-nofocus">
                              <Icon name="notice"></Icon>
                              <span>{post.totalComments}</span>
                            </Button>
                          </div>
                        </div>
                        <div className="py-3">
                          <h5 className="card-title">{post.title}</h5>
                          {post.shortDesc && (
                            <p className="card-text">{post.shortDesc}</p>
                          )}
                          <p className="card-text">{parse(post.content)}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Card>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default PostDetailsPage;
